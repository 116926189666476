import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styles from "../Scss/SliderWorking.module.scss";
import nextArrow from "./../Images/Main/NextArrow.png";
import prevArrow from "./../Images/Main/PrevArrow.png";

function SampleNextArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div
			className={className}
			style={{
				...style,
				display: "block",
				background: "rgba(0, 0, 0, 0.3)",
				width: "30px",
				height: "30px",
				zIndex: "5",
				right: "-14px",
				borderRadius: "50%",
			}}
			onClick={onClick}>
			<img className={styles.arrow_img} src={nextArrow} alt='NextArrow' />
		</div>
	);
}

function SamplePrevArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div
			className={className}
			style={{
				...style,
				display: "block",
				background: "rgba(0, 0, 0, 0.3)",
				width: "30px",
				height: "30px",
				zIndex: "5",
				left: "-14px",
				borderRadius: "50%",
			}}
			onClick={onClick}>
			<img className={styles.arrow_img} src={prevArrow} alt='PrevArrow' />
		</div>
	);
}

function SliderWorking() {
	const settings = {
		dots: true,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		speed: 500,
		cssEase: "linear",
		pauseOnHover: true,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
	};
	return (
		<div className={styles.slider_container}>
			<Slider {...settings}>
				<div className={`${styles.working_card} ${styles.working_1}`}>
					<p className={styles.working_card_title}>Проведение исследования</p>
				</div>
				<div className={`${styles.working_card} ${styles.working_2}`}>
					<p className={styles.working_card_title}>Проектирование</p>
				</div>
				<div className={`${styles.working_card} ${styles.working_3}`}>
					<p className={styles.working_card_title}>
						Подбор и согласование решения
					</p>
				</div>
				<div className={`${styles.working_card} ${styles.working_4}`}>
					<p className={styles.working_card_title}>Поставка оборудования</p>
				</div>
				<div className={`${styles.working_card} ${styles.working_5}`}>
					<p className={styles.working_card_title}>
						Строительно-монтажные работы
					</p>
				</div>
				<div className={`${styles.working_card} ${styles.working_6}`}>
					<p className={styles.working_card_title}>
						Интеграция системы управления
					</p>
				</div>
				<div className={`${styles.working_card} ${styles.working_7}`}>
					<p className={styles.working_card_title}>Пуско-наладочные работы</p>
				</div>
				<div className={`${styles.working_card} ${styles.working_8}`}>
					<p className={styles.working_card_title}>
						Подтверждение заявленных <br />
						характеристик
					</p>
				</div>
				<div className={`${styles.working_card} ${styles.working_9}`}>
					<p className={styles.working_card_title}>Обслуживание</p>
				</div>
			</Slider>
		</div>
	);
}

export default SliderWorking;
