import { React, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { NavLink, Route, Routes } from "react-router-dom";
import Company from "../Pages/Company";
import Contacts from "../Pages/Contacts";
import Home from "../Pages/Home";
import Services from "../Pages/Services";
import styles from "../Scss/Header.module.scss";
import logo from "./../Images/logo-1.1.png";
import BurgerMenue from "./BurgerMenue";

const Header = () => {
	const [burgerActive, setBurgerActive] = useState(false);
	return (
		<>
			<header className={styles.container}>
				<nav className={styles.header}>
					<GiHamburgerMenu
						className={styles.header_burger}
						onClick={() => setBurgerActive(true)}
					/>
					<img src={logo} className={styles.header_logo} alt='logo' />
					<NavLink className={styles.header_link} to='/'>
						ГЛАВНАЯ
					</NavLink>
					<NavLink className={styles.header_link} to='/services'>
						УСЛУГИ
					</NavLink>
					<NavLink className={styles.header_link} to='/company'>
						О КОМПАНИИ
					</NavLink>
					<NavLink className={styles.header_link} to='/contacts'>
						КОНТАКТЫ
					</NavLink>

					<div className={styles.header_title}>
						{/* <p className={styles.header_subtitle}>info@ipkpsi.ru</p>
						<p className={styles.header_subtitle}>+7 (495) 748-97-61</p>
						 <a className={styles.header_subtitle} href='tel:+74957849761'>
						+7 (495) 748-97-61
					</a> 
						<p className={styles.header_subtitle}>+7 (926) 902-74-67</p> */}
					</div>
					<BurgerMenue active={burgerActive} setActive={setBurgerActive} />
				</nav>
			</header>

			<Routes>
				<Route path='/' element={<Home />} />
				<Route path='/services' element={<Services />} />
				<Route path='/company' element={<Company />} />
				<Route path='/contacts' element={<Contacts />} />
			</Routes>
		</>
	);
};

export default Header;
