import AutoPlay from "../Components/AutoPlay";
import Question from "../Components/Question";
import SliderWorking from "../Components/SlaiderWorking";
import SliderClients from "../Components/SliderClients";
import SliderClientsMobile from "../Components/SliderClientsMobile";
import offer1 from "../Images/Main/offer_1.png";
import offer2 from "../Images/Main/offer_2.JPG";
import offer3 from "../Images/Main/offer_3.png";
import offer4 from "../Images/Main/offer_4.png";
import offer5 from "../Images/Main/offer_5.png";
import offer6 from "../Images/Main/offer_6.png";
import principles_1 from "../Images/Main/principles_1.jpg";
import principles_2 from "../Images/Main/principles_2.jpg";
import principles_3 from "../Images/Main/principles_3.jpg";
import working_arrow from "../Images/Main/working_arrow.jpg";
import working_arrow1 from "../Images/Main/working_arrow1.jpg";
import styles from "../Scss/Home.module.scss";

const Home = () => {
	return (
		<>
			<AutoPlay />
			<div className={styles.services}>
				<h1 className={styles.services_title}>Мы предлагаем</h1>
				<div className={styles.services_cards}>
					<div className={styles.services_card}>
						<img
							className={styles.services_card_img}
							alt='complex approach'
							src={offer1}
						/>
						<p className={styles.services_card_title}>
							Комплексный подход в решениях оптимизации воздушных и газовых
							систем
						</p>
					</div>
					<div className={styles.services_card}>
						<img
							className={styles.services_card_img}
							alt='Development/Protection'
							src={offer2}
						/>
						<p className={styles.services_card_title}>
							Разработку проектной документации и защиту проекта в органах
							госнадзора
						</p>
					</div>
					<div className={styles.services_card}>
						<img
							className={styles.services_card_img}
							alt='Increasing energy'
							src={offer3}
						/>
						<p className={styles.services_card_title}>
							Повышение энергоэффективности пневмосистем
						</p>
					</div>
					<div className={styles.services_card}>
						<img
							className={styles.services_card_img}
							alt='Conducting surveys'
							src={offer4}
						/>
						<p className={styles.services_card_title}>
							Проведение обследований действующих пневмосистем
						</p>
					</div>
					<div className={styles.services_card}>
						<img
							className={styles.services_card_img}
							alt='Warranty service'
							src={offer5}
						/>
						<p className={styles.services_card_title}>
							Гарантийное и постгарантийное обслуживание
						</p>
					</div>
					<div className={styles.services_card}>
						<img
							className={styles.services_card_img}
							alt='Carrying out work'
							src={offer6}
						/>
						<p className={styles.services_card_title}>
							Проведение шеф - монтажных и пусконаладочных работ
						</p>
					</div>
				</div>
			</div>
			{/* -------------------------------------------------------------------------------------- */}
			<div className={styles.working}>
				<h1 className={styles.working_title}>Как мы работаем</h1>
				<div className={styles.working_cards}>
					<div className={`${styles.working_card} ${styles.working_1}`}>
						<p className={styles.working_card_title}>
							<span className={styles.working_card_span}></span> Проведение
							исследования
						</p>
					</div>
					<img src={working_arrow} alt='arrow' />
					<div className={`${styles.working_card} ${styles.working_2}`}>
						<p className={styles.working_card_title}>
							<span className={styles.working_card_span}></span>Проектирование
						</p>
					</div>
					<img src={working_arrow} alt='arrow' />
					<div className={`${styles.working_card} ${styles.working_3}`}>
						<p className={styles.working_card_title}>
							Подбор и согласование решения
						</p>
					</div>
					{/* ------------------------------------------------- */}
					<img
						src={working_arrow1}
						alt='arrow'
						className={styles.working_arrow_back}
					/>
					<div className={`${styles.working_card} ${styles.working_4}`}>
						<p className={styles.working_card_title}>
							<span className={styles.working_card_span}></span>Поставка
							оборудования
						</p>
					</div>
					<img src={working_arrow} alt='arrow' />
					<div className={`${styles.working_card} ${styles.working_5}`}>
						<p className={styles.working_card_title}>
							Строительно-монтажные работы
						</p>
					</div>
					<img src={working_arrow} alt='arrow' />
					<div className={`${styles.working_card} ${styles.working_6}`}>
						<p className={styles.working_card_title}>
							Интеграция системы управления
						</p>
					</div>
					{/* --------------------------------------------------- */}
					<img
						src={working_arrow1}
						alt='arrow'
						className={styles.working_arrow_back}
					/>
					<div className={`${styles.working_card} ${styles.working_7}`}>
						<p className={styles.working_card_title}>
							<span className={styles.working_card_span}></span>Пуско-наладочные
							работы
						</p>
					</div>
					<img src={working_arrow} alt='arrow' />
					<div className={`${styles.working_card} ${styles.working_8}`}>
						<p className={styles.working_card_title}>
							Подтверждение заявленных характеристик
						</p>
					</div>
					<img src={working_arrow} alt='arrow' />
					<div className={`${styles.working_card} ${styles.working_9}`}>
						<p className={styles.working_card_title}>
							<span className={styles.working_card_span}></span>Обслуживание
						</p>
					</div>
				</div>
				<SliderWorking />
			</div>
			{/* -------------------------------------------------------------------------------------- */}
			<div className={styles.principles}>
				<h1 className={styles.principles_title}>Базовые принципы</h1>
				<div className={styles.principles_cards}>
					<div className={styles.principles_card}>
						<img
							src={principles_1}
							alt='principles'
							className={styles.principles_img}
						/>
						<p className={styles.principles_card_title}>
							Решение технических задач
						</p>
					</div>
					<div className={styles.principles_card}>
						<img
							src={principles_2}
							alt='principles'
							className={styles.principles_img}
						/>
						<p className={styles.principles_card_title}>
							Повышение энергоэффективности
						</p>
					</div>
					<div className={styles.principles_card}>
						<img
							src={principles_3}
							alt='principles'
							className={styles.principles_img}
						/>
						<p className={styles.principles_card_title}>Импортозамещение</p>
					</div>
				</div>
			</div>
			{/* -------------------------------------------------------------------------------------- */}
			<Question />
			<div className={styles.clients}>
				<h1 className={styles.clients_title}>Наши партнёры</h1>
				<SliderClients />
				<SliderClientsMobile />
			</div>
		</>
	);
};

export default Home;
