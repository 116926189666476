import React from "react";
import { NavLink } from "react-router-dom";
import styles from "../Scss/BurgerMenue.module.scss";

const BurgerMenue = ({ active, setActive }) => {
	return (
		<div
			className={active ? `${styles.burger_active}` : `${styles.burger}`}
			onClick={() => setActive(false)}>
			<div className={styles.burger_content} onClick={e => e.stopPropagation()}>
				{/* <IoMdCloseCircleOutline
					className={styles.burger_close}
					onClick={() => setActive(false)}
				/> */}
				<NavLink
					className={styles.header_link}
					to='/'
					onClick={() => setActive(false)}>
					ГЛАВНАЯ
				</NavLink>
				<NavLink
					className={styles.header_link}
					to='/services'
					onClick={() => setActive(false)}>
					УСЛУГИ
				</NavLink>
				<NavLink
					className={styles.header_link}
					to='/company'
					onClick={() => setActive(false)}>
					О КОМПАНИИ
				</NavLink>
				<NavLink
					className={styles.header_link}
					to='/contacts'
					onClick={() => setActive(false)}>
					КОНТАКТЫ
				</NavLink>
			</div>
		</div>
	);
};

export default BurgerMenue;
