import "./App.scss";
import Footer from "./Components/Footer";
import Header from "./Components/Header";

function App() {
	return (
		<div className='App'>
			<Header />
			<Footer />
		</div>
	);
}

export default App;
