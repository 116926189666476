import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styles from "../Scss/AutoPlay.module.scss";
import nextArrow from "./../Images/Main/NextArrow.png";
import prevArrow from "./../Images/Main/PrevArrow.png";
import slide1 from "./../Images/Slider/Slide1.jpg";
import slide2 from "./../Images/Slider/Slide2.jpg";
import slide3 from "./../Images/Slider/Slide3.jpg";
import slide4 from "./../Images/Slider/Slide4.jpg";
import slide5 from "./../Images/Slider/Slide5.jpg";
import slide6 from "./../Images/Slider/Slide6.jpg";

function SampleNextArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div
			className={className}
			style={{
				...style,
				display: "none",
				background: "rgba(0, 0, 0, 0.3)",
				width: "80px",
				zIndex: "5",
				height: "80px",
				right: "100px",
				borderRadius: "50%",
			}}
			onClick={onClick}>
			<img className={styles.arrow_img} src={nextArrow} alt='NextArrow' />
		</div>
	);
}

function SamplePrevArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div
			className={className}
			style={{
				...style,
				display: "none",
				background: "rgba(0, 0, 0, 0.3)",
				width: "80px",
				zIndex: "5",
				height: "80px",
				left: "100px",
				borderRadius: "50%",
			}}
			onClick={onClick}>
			<img className={styles.arrow_img} src={prevArrow} alt='PrevArrow' />
		</div>
	);
}

function AutoPlay() {
	const settings = {
		dots: true,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		speed: 3000,
		autoplaySpeed: 7000,
		cssEase: "linear",
		pauseOnHover: false,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
	};
	return (
		<div className={styles.slider_container}>
			<Slider {...settings}>
				<div>
					<img className={styles.slider_image} src={slide1} alt='logo' />
				</div>
				<div>
					<img className={styles.slider_image} src={slide2} alt='logo' />
				</div>
				<div>
					<img className={styles.slider_image} src={slide3} alt='logo' />
				</div>
				<div>
					<img className={styles.slider_image} src={slide4} alt='logo' />
				</div>
				<div>
					<img className={styles.slider_image} src={slide5} alt='logo' />
				</div>
				<div>
					<img className={styles.slider_image} src={slide6} alt='logo' />
				</div>
			</Slider>
		</div>
	);
}

export default AutoPlay;
