import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import works_img1 from "../Images/Ours/2.jpeg";
import works_img2 from "../Images/Ours/2.jpeg.jpg";
import works_img3 from "../Images/Ours/4.jpeg";
import works_img4 from "../Images/Ours/7.jpeg";

import works_img5 from "../Images/Ours/хз.jpeg";
import works_img6 from "../Images/Ours/хз2.jpeg";

import styles from "../Scss/WorksCardsMobile.module.scss";
import prevArrow from "./../Images/Partners/Arrow_left.png";
import nextArrow from "./../Images/Partners/Arrow_right.png";

function SampleNextArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div
			className={className}
			style={{
				...style,
				display: "block",
				width: "40px",
				height: "40px",
				zIndex: "5",
				right: "15px",
				border: "1px solid #FF7E00",
				backgroundColor: "rgba(255, 255, 255, 0.4)",
				borderRadius: "25px",
			}}
			onClick={onClick}>
			<img className={styles.arrow_img} src={nextArrow} alt='NextArrow' />
		</div>
	);
}

function SamplePrevArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div
			className={className}
			style={{
				...style,
				display: "block",
				width: "40px",
				height: "40px",
				zIndex: "5",
				left: "15px",
				backgroundColor: "rgba(255, 255, 255, 0.4)",
				borderRadius: "25px",
				border: "1px solid #FF7E00",
			}}
			onClick={onClick}>
			<img className={styles.arrow_img} src={prevArrow} alt='PrevArrow' />
		</div>
	);
}

function WorksCardsMobile() {
	const settings = {
		dots: true,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		speed: 500,
		cssEase: "linear",
		pauseOnHover: true,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
	};
	return (
		<div className={styles.wcm_container}>
			<Slider {...settings}>
				<div className={styles.wcm_card}>
					<img src={works_img1} alt='Client' className={styles.wcm_img} />
				</div>
				<div className={styles.wcm_card}>
					<img src={works_img2} alt='Client' className={styles.wcm_img} />
				</div>
				<div className={styles.wcm_card}>
					<img src={works_img3} alt='Client' className={styles.wcm_img} />
				</div>
				<div className={styles.wcm_card}>
					<img src={works_img4} alt='Client' className={styles.wcm_img} />
				</div>
				<div className={styles.wcm_card}>
					<img src={works_img5} alt='Client' className={styles.wcm_img} />
				</div>
				<div className={styles.wcm_card}>
					<img src={works_img6} alt='Client' className={styles.wcm_img} />
				</div>
			</Slider>
		</div>
	);
}

export default WorksCardsMobile;
