import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import client_3 from "../Images/Certificates/chj1.jpg";
import client_2 from "../Images/Certificates/chj2.jpg";
import client_1 from "../Images/Certificates/sertifikat-1.jpg";

import styles from "../Scss/SSM.module.scss";
import prevArrow from "./../Images/Partners/Arrow_left.png";
import nextArrow from "./../Images/Partners/Arrow_right.png";

function SampleNextArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div
			className={className}
			style={{
				...style,
				display: "block",
				width: "30px",
				height: "20px",
				zIndex: "5",
				right: "0px",
				border: "none",
			}}
			onClick={onClick}>
			<img className={styles.arrow_img} src={nextArrow} alt='NextArrow' />
		</div>
	);
}

function SamplePrevArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div
			className={className}
			style={{
				...style,
				display: "block",
				width: "30px",
				height: "20px",
				zIndex: "5",
				left: "0px",
				border: "none",
			}}
			onClick={onClick}>
			<img className={styles.arrow_img} src={prevArrow} alt='PrevArrow' />
		</div>
	);
}

function SliderSertificatesMobile() {
	const settings = {
		dots: true,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		speed: 500,
		cssEase: "linear",
		pauseOnHover: true,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
	};
	return (
		<div className={styles.ssm_container}>
			<Slider {...settings}>
				<div className={styles.ssm_card}>
					<img src={client_1} alt='Client' className={styles.ssm_img} />
					<p className={styles.ssm_card_title}>
						Сертификат системы менеджмента качества ISO 9001
					</p>
				</div>
				<div className={styles.ssm_card}>
					<img src={client_2} alt='Client' className={styles.ssm_img} />
					<p className={styles.ssm_card_title}>Альянс строителей</p>
				</div>
				<div className={styles.ssm_card}>
					<img src={client_3} alt='Client' className={styles.ssm_img} />
					<p className={styles.ssm_card_title}>Совет проектировщиков</p>
				</div>
			</Slider>
		</div>
	);
}

export default SliderSertificatesMobile;
