import Question from "../Components/Question";
import SliderSertificatesMobile from "../Components/SliderSertificatesMobile";
import WorksCardsMobile from "../Components/WorksCardsMobile";
import construction from "../Images/About_company/construction2.jpg";
import design from "../Images/About_company/design.jpg";
import engineering from "../Images/About_company/engineering.jpg";
import research from "../Images/About_company/research.jpg";
import stock from "../Images/About_company/stock.jpg";
import teams from "../Images/About_company/teams.jpg";
import research3 from "../Images/Certificates/chj1.jpg";
import research2 from "../Images/Certificates/chj2.jpg";
import research1 from "../Images/Certificates/sertifikat-1.jpg";
import works_img1 from "../Images/Ours/2.jpeg";
import works_img2 from "../Images/Ours/2.jpeg.jpg";
import works_img3 from "../Images/Ours/4.jpeg";
import works_img4 from "../Images/Ours/7.jpeg";
import works_img7 from "../Images/Ours/тобольск ханва.jpeg.jpg";
import works_img5 from "../Images/Ours/хз.jpeg";
import works_img6 from "../Images/Ours/хз2.jpeg";
import styles from "../Scss/Company.module.scss";

const Company = () => {
	return (
		<>
			<div className={styles.container}>
				<div className={styles.company}>
					{/* <h1 className={styles.company_title}>О компании: </h1> */}
					<p className={styles.company_subtitle}>
						ООО «Инвестиционно-промышленная компания Промстройинжиниринг» (ООО
						«ИПК ПСИ») специализируется на разработке и реализации
						инвестиционных проектов в области модернизации и реконструкции
						компрессорных станций.
					</p>
				</div>
			</div>
			{/* -------------------------------------------------------------------------------------- */}
			<div className={styles.container}>
				<div className={styles.resources}>
					<h1 className={styles.resources_title}>Наши ресурсы</h1>
					<div className={styles.resources_cards}>
						<div className={styles.resources_card}>
							<img
								className={styles.resources_card_img1}
								src={design}
								alt='resources'
							/>
							<p className={styles.resources_card_title}>
								СРО на проектирование
							</p>
						</div>
						<div className={styles.resources_card}>
							<img
								className={styles.resources_card_img2}
								src={construction}
								alt='resources'
							/>
							<p className={styles.resources_card_title}>
								СРО на строительство
							</p>
						</div>
						<div className={styles.resources_card}>
							<img
								className={styles.resources_card_img3}
								src={stock}
								alt='resources'
							/>
							<p className={styles.resources_card_title}>
								Собственный склад <br />
								{/* <span className={styles.resources_card_subtitle}>
									для хранения расходных материалов и комплектующих
								</span> */}
							</p>
						</div>
						<div className={styles.resources_card}>
							<img
								className={styles.resources_card_img4}
								src={teams}
								alt='resources'
							/>
							<p className={styles.resources_card_title}>
								Собственные бригады <br />
								{/* <span className={styles.resources_card_subtitle}>
									для проведения СМР
								</span> */}
							</p>
						</div>
						<div className={styles.resources_card}>
							<img
								className={styles.resources_card_img5}
								src={engineering}
								alt='resources'
							/>
							<p className={styles.resources_card_title}>Инженерный центр</p>
						</div>
						<div className={styles.resources_card}>
							<img
								className={styles.resources_card_img6}
								src={research}
								alt='resources'
							/>
							<p className={styles.resources_card_title}>
								Исследовательский центр
							</p>
						</div>
					</div>
				</div>
			</div>
			{/* -------------------------------------------------------------------------------------- */}
			<div className={styles.container}>
				<div className={styles.certificates}>
					<h1 className={styles.certificates_title}>
						Свидетельства и сертификаты
					</h1>
					<p className={styles.certificates_subtitle}>
						Компания ООО «Промстройинжиниринг» является членом «СРО «Альянс
						Строителей» и «СРО «Совет Проектировщиков». Так же наша компания
						имеет сертификат качества менеджмента ISO 9001.
					</p>
					<div className={styles.certificates_cards}>
						<div className={styles.certificates_card}>
							<img
								className={styles.certificates_card_img}
								src={research1}
								alt='certificate'
							/>
							<p className={styles.certificates_card_title}>
								Сертификат системы менеджмента качества ISO 9001
							</p>
						</div>
						<div className={styles.certificates_card}>
							<img
								className={styles.certificates_card_img}
								src={research2}
								alt='certificate'
							/>
							<p className={styles.certificates_card_title}>
								Альянс строителей
							</p>
						</div>
						<div className={styles.certificates_card}>
							<img
								className={styles.certificates_card_img}
								src={research3}
								alt='certificate'
							/>
							<p className={styles.certificates_card_title}>
								Совет проектировщиков
							</p>
						</div>
					</div>
					<SliderSertificatesMobile />
				</div>
			</div>
			{/* -------------------------------------------------------------------------------------- */}
			<div className={`${styles.container} ${styles.grey}`}>
				<div className={styles.works}>
					<h1 className={styles.works_title}>Наши работы</h1>
					<div className={styles.works_cards}>
						<img
							className={styles.works_img}
							src={works_img1}
							alt='Our works'
						/>
						<img
							className={styles.works_img}
							src={works_img2}
							alt='Our works'
						/>
						<img
							className={styles.works_img}
							src={works_img3}
							alt='Our works'
						/>
						<img
							className={styles.works_img}
							src={works_img4}
							alt='Our works'
						/>
						<img
							className={styles.works_img}
							src={works_img5}
							alt='Our works'
						/>
						<img
							className={styles.works_img}
							src={works_img6}
							alt='Our works'
						/>
						<img
							className={styles.works_imgBig}
							src={works_img7}
							alt='Our works'
						/>
					</div>
					<WorksCardsMobile />
				</div>
			</div>
			<Question />
		</>
	);
};

export default Company;
