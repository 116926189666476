import React, { useState } from "react";
import styles from "../Scss/Question.module.scss";
import Modal from "./Modal";

const Question = () => {
	const [modalActive, setModalActive] = useState(false);
	return (
		<>
			<div className={styles.question}>
				<h1 className={styles.question_title}>Остались вопросы?</h1>
				<p className={styles.question_subtitle}>
					Заполните форму обратной связи и мы свяжемся с Вами!
				</p>
				<button
					className={styles.question_btn}
					onClick={() => setModalActive(true)}>
					<p className={styles.question_btn_title}>ЗАДАТЬ ВОПРОС</p>
				</button>
			</div>
			<Modal active={modalActive} setActive={setModalActive} />
		</>
	);
};

export default Question;
