import emailjs from "@emailjs/browser";
import React from "react";
import { useForm } from "react-hook-form";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { useHookFormMask } from "use-mask-input";
import styles from "../Scss/Modal.module.scss";

const Modal = ({ active, setActive }) => {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({
		defaultValues: { yes_i_understand: false },
	});
	const registerWithMask = useHookFormMask(register);

	const sendEmail = formData => {
		// e.preventDefault();
		console.log("formData", formData);

		emailjs
			.send("service_alac969", "template_m0z6m8c", formData, {
				publicKey: "lcYaiWjYfZgu_SL6j",
			})
			.then(
				() => {
					console.log("SUCCESS!");
					setActive(false);
				},
				error => {
					console.log("FAILED...", error.text);
				},
			);
		reset();
	};
	return (
		<div
			className={active ? `${styles.modal_active}` : `${styles.modal}`}
			onClick={() => setActive(false)}>
			<div className={styles.modal_content} onClick={e => e.stopPropagation()}>
				<IoMdCloseCircleOutline
					className={styles.form_close}
					onClick={() => setActive(false)}
				/>
				<form onSubmit={handleSubmit(sendEmail)} className={styles.form}>
					<div className={styles.form_box}>
						<input
							id='name'
							type='text'
							name='name'
							placeholder='Ваше имя'
							className={styles.input}
							{...register("name", {
								required: true,
							})}
						/>
						{errors.name && (
							<div className={styles.form_invalid}>
								Поле обязательно в заполению.
							</div>
						)}
					</div>
					<div className={styles.form_box}>
						<input
							id='from_email'
							type='email'
							name='from_email'
							placeholder='Эл.почта'
							className={styles.input}
							{...register("from_email", {
								required: true,
							})}
						/>
						{errors.from_email && (
							<div className={styles.form_invalid}>
								Поле обязательно в заполению.
							</div>
						)}
					</div>
					<div className={styles.form_box}>
						<input
							id='from_phone'
							name='from_phone'
							placeholder='Телефон'
							className={styles.input}
							{...registerWithMask(
								"from_phone",
								["9 (999) 999-99-99", "+9 (999) 999-99-99"],
								{
									required: true,
								},
							)}
						/>
						{errors.from_phone && (
							<div className={styles.form_invalid}>
								Поле обязательно в заполению.
							</div>
						)}
					</div>
					<div className={styles.submit_box}>
						<button type='submit' className={styles.submit}>
							Отправить
						</button>

						<div className={styles.form_box}>
							<input
								type='checkbox'
								id='check'
								name='check'
								className={styles.checkbox}
								{...register("yes_i_understand", { required: true })}
							/>
							<label for='check' className={styles.checkbox_title}>
								Нажимая кнопку «Отправить», я соглашаюсь с условиями Политики в
								отношении обработки персональных данных
							</label>
							{errors.yes_i_understand && (
								<div className={styles.form_invalid}>
									Вы должны подтвердить условия Политики в отношении обработки
									персональных данных.
								</div>
							)}
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default Modal;
