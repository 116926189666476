import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import client_1 from "../Images/Partners/client_1.png";
import client_2 from "../Images/Partners/client_2.svg";
import client_3 from "../Images/Partners/client_3.jpg";
import client_4 from "../Images/Partners/client_4.jpg";
import client_5 from "../Images/Partners/client_5.jpg";
import client_6 from "../Images/Partners/client_6.JPG";
import client_7 from "../Images/Partners/client_7.png";
import client_8 from "../Images/Partners/client_8.jpg";
import styles from "../Scss/SliderClientsMobile.module.scss";
import prevArrow from "./../Images/Partners/Arrow_left.png";
import nextArrow from "./../Images/Partners/Arrow_right.png";

function SampleNextArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div
			className={className}
			style={{
				...style,
				display: "block",
				width: "40px",
				height: "20px",
				zIndex: "5",
				right: "-43px",
				border: "none",
			}}
			onClick={onClick}>
			<img className={styles.arrow_img} src={nextArrow} alt='NextArrow' />
		</div>
	);
}

function SamplePrevArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div
			className={className}
			style={{
				...style,
				display: "block",
				width: "40px",
				height: "20px",
				zIndex: "5",
				left: "-43px",
				border: "none",
			}}
			onClick={onClick}>
			<img className={styles.arrow_img} src={prevArrow} alt='PrevArrow' />
		</div>
	);
}

function SliderClientsMobile() {
	const settings = {
		dots: true,
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		speed: 500,
		cssEase: "linear",
		pauseOnHover: true,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
	};
	return (
		<div className={styles.slider_container}>
			<Slider {...settings}>
				<div className={styles.client_card}>
					<img src={client_1} alt='Client' className={styles.client_img} />
				</div>
				<div className={styles.client_card}>
					<img src={client_2} alt='Client' className={styles.client_img} />
				</div>
				<div className={styles.client_card}>
					<img src={client_3} alt='Client' className={styles.client_img} />
				</div>
				<div className={styles.client_card}>
					<img src={client_4} alt='Client' className={styles.client_img} />
				</div>
				<div className={styles.client_card}>
					<img src={client_5} alt='Client' className={styles.client_img} />
				</div>
				<div className={styles.client_card}>
					<img src={client_6} alt='Client' className={styles.client_img} />
				</div>
				<div className={styles.client_card}>
					<img src={client_7} alt='Client' className={styles.client_img} />
				</div>
				<div className={styles.client_card}>
					<img src={client_8} alt='Client' className={styles.client_img} />
				</div>
			</Slider>
		</div>
	);
}

export default SliderClientsMobile;
