import logo from "../Images/logo-1.1.png";
import styles from "../Scss/Footer.module.scss";

const Footer = () => {
	return (
		<>
			<div className={styles.container}>
				<div className={styles.footer}>
					<p className={styles.title}>
						2015 ИНВЕСТИЦИОННО-ПРОМЫШЛЕННАЯ КОМПАНИЯ «ПРОМСТРОЙИНЖИНИРИНГ»
					</p>
					<img className={styles.img} src={logo} alt='Logo' />
					<div className={styles.subtitle}>
						<a className={styles.subtitle_link} href='tel:+74957849761'>
							+7 (495) 748-97-61
						</a>
						<a className={styles.subtitle_link} href='tel:+79269027467'>
							+7 (926) 902-74-67
						</a>
						<a className={styles.subtitle_link} href='email:info@ipkpsi.ru'>
							info@ipkpsi.ru
						</a>
					</div>
				</div>
			</div>
		</>
	);
};

export default Footer;
