import img1 from "../Images/Services/1.jpg";
import img2 from "../Images/Services/2.jpg";
import img3 from "../Images/Services/3.png";
import img4 from "../Images/Services/4.png";
import img5 from "../Images/Services/5.jpg";
import img6 from "../Images/Services/6.jpg";
import img7 from "../Images/Services/7.jpg";
import styles from "../Scss/Services.module.scss";

const Services = () => {
	return (
		<>
			<div className={styles.container}>
				<div className={styles.main}>
					<h1 className={styles.title}>
						Наша компания осуществляет разработку проектной и рабочей
						документации, поставку и строительно-монтажные работы отдельных
						узлов или комплексных промышленных систем
					</h1>
				</div>
			</div>
			<div className={styles.container}>
				<div className={styles.cards}>
					<div className={styles.card}>
						<img src={img1} alt='services' className={styles.img} />
						<p className={styles.card_txt}>
							Выработка и подача сжатого воздуха
						</p>
					</div>
					<div className={styles.card}>
						<img src={img2} alt='services' className={styles.img} />
						<p className={styles.card_txt}>Воздухоподготовка</p>
					</div>
					<div className={styles.card}>
						<img src={img3} alt='services' className={styles.img} />
						<p className={styles.card_txt}>
							Водооборотные циклы с применением различных типов градирен
						</p>
					</div>
					<div className={styles.card}>
						<img src={img4} alt='services' className={styles.img} />
						<p className={styles.card_txt}>Насосные станции</p>
					</div>
					<div className={styles.card}>
						<img src={img5} alt='services' className={styles.img} />
						<p className={styles.card_txt}>
							Компрессорные станции по компримированию технических газов
						</p>
					</div>
					<div className={styles.card}>
						<img src={img6} alt='services' className={styles.img} />
						<p className={styles.card_txt}>
							Воздухоразделительные станции с возможностью блочно-модульного
							исполнения
						</p>
					</div>
					<div className={styles.card}>
						<img src={img7} alt='services' className={styles.img} />
						<p className={styles.card_txt}>Система мониторинга процессов</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default Services;
