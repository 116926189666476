import { Map, Placemark, YMaps } from "@pbe/react-yandex-maps";
import {
	MdAlternateEmail,
	MdCall,
	MdOutlineMail,
	MdRoom,
} from "react-icons/md";

import { ContactUs } from "../Components/Form";
import styles from "../Scss/Contacts.module.scss";

const Contacts = () => {
	return (
		<>
			<div className={styles.container}>
				<div className={styles.contacts}>
					<div className={styles.connection}>
						<h1 className={styles.title}>ООО «ИПК ПСИ»</h1>
						<div className={styles.contact}>
							<MdCall className={styles.phone_img} />
							<div className={styles.phones}>
								<a className={styles.contact_a} href='tel:+74957849761'>
									+7 (495) 748-97-61
								</a>
								<a className={styles.contact_a} href='tel:+79269027467'>
									+7 (926) 902-74-67
								</a>
							</div>
						</div>
						<div className={styles.contact}>
							{/* <p className={styles.email_img}>@</p> */}
							<MdAlternateEmail className={styles.email_img} />
							<a className={styles.contact_a} href='mailto:info@ipkpsi.ru7'>
								info@ipkpsi.ru
							</a>
						</div>
						<div className={styles.contact}>
							<MdOutlineMail className={styles.email_img} />
							<p className={styles.contact_a}>123022</p>
						</div>
						<div className={styles.address}>
							<MdRoom className={styles.email_img} />
							<p className={styles.contact_a}>
								г. Москва, ул. 2-я Звенигородская, д. 13, стр. 41, каб. 9, 9А
							</p>
						</div>
					</div>
					<div className={styles.map}>
						{/* 55.761396, 37.552669 */}
						<YMaps>
							<Map
								className={styles.map_style}
								defaultState={{
									center: [55.761396, 37.552669],
									zoom: 15,
									controls: ["zoomControl", "fullscreenControl"],
								}}
								// width={500}
								// height={500}
								modules={["control.ZoomControl", "control.FullscreenControl"]}>
								<Placemark
									modules={["geoObject.addon.balloon"]}
									defaultGeometry={[55.761396, 37.552669]}
									properties={{
										balloonContentBody: "ООО Промстройинжиниринг",
									}}
								/>
							</Map>
						</YMaps>
					</div>
				</div>

				<ContactUs />
			</div>
		</>
	);
};

export default Contacts;
